var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: {
            title:
              "'" +
              _vm.popupParam.requestContents +
              "'에 대한 개선요청 엑셀업로드",
          },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "적용", icon: "save" },
                        on: { btnClicked: _vm.acceptData },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    codeGroupCd: "IBM_TASK_TYPE_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "ibmTaskTypeCd",
                    label: "업무",
                  },
                  model: {
                    value: _vm.popupParam.ibmTaskTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.popupParam, "ibmTaskTypeCd", $$v)
                    },
                    expression: "popupParam.ibmTaskTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "점검자",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-excel-upload", {
        attrs: {
          editable: _vm.editable,
          excelUploadInfo: _vm.excelUploadInfo,
          plantCd: _vm.popupParam.plantCd,
          processCd: _vm.popupParam.processCd,
          changeItem: _vm.popupParam.changeItem,
        },
        on: {
          "update:changeItem": function ($event) {
            return _vm.$set(_vm.popupParam, "changeItem", $event)
          },
          "update:change-item": function ($event) {
            return _vm.$set(_vm.popupParam, "changeItem", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }